import React from "react";
import GlobalLayout from "components/page/GlobalLayout";
import { Helmet } from "react-helmet";
import WistiaVideoHeader from "components/common/hero/WistiaVideoHeader";
import InfoSlider from "components/common/InfoSlider";
import IndustryFeaturedUpdated from "components/industries/updated/IndustryFeaturedUpdated";
import { StaticQuery, graphql } from "gatsby";
import CTABanner from "components/common/footer-banners/CTABanner";

export default function E2DemoLandingPage() {
  const renderContent = data => {
    const videoId = "g0ye66g4zi ";
    const infoSlides = [
      {
        tabTitle: "User-Friendly",
        title: "Keep Students and Faculty Safe with an Easy-to-Use Platform",
        description:
          "Fortify school security with cloud-based cameras and real-time AI alerts for proactive threat detection anytime, anywhere. Cover blind spots, support School Resource Officers, and streamline investigations with easy-to-use tools.",
        image: data.infoSlide1,
      },
      {
        tabTitle: "Proactive Responses",
        title:
          "Accelerate Emergency Response and Coordinate with First Responders",
        description:
          "Act swiftly in emergencies and access a range of solutions, including person-of-interest detection, panic buttons, and alarm monitoring. Verify threats, reduce false alarms, and relay key details to emergency services efficiently.",
        image: data.infoSlide2,
      },
      {
        tabTitle: "Prevent Vaping",
        title: "Monitor Air Quality and Detect Student Vaping",
        description:
          "Maintain a healthy school environment and reduce student vaping with air quality and vape/smoke/THC smart sensors. Automatically detect e-cigarette and tobacco use in real time and obtain actionable evidence while preserving student privacy. ",
        image: data.infoSlide3,
      },
      {
        tabTitle: "Monitor Access Points",
        title: "Control Campus Perimeters and Building Access",
        description:
          "Centrally manage unlimited doors, buildings, and credentials from anywhere. Detect unauthorized visitors and quickly understand who’s on school property by pairing access events with synchronized video, badge data, and timestamps. ",
        image: data.infoSlide4,
      },
    ];

    const logos = [
      {
        img: data.logo1,
        width: "89px",
        alt: "Clark Atlanta Univeristy",
      },
      {
        img: data.logo2,
        width: "118px",
        alt: "Jesuit",
      },
      {
        img: data.logo3,
        width: "135px",
        alt: "Mississippi Department of Education",
      },
      {
        img: data.logo4,
        width: "99px",
        alt: "Pacific University Oregon",
      },
      {
        img: data.logo5,
        width: "174px",
        alt: "Conestoga Valley",
      },
      {
        img: data.logo6,
        width: "158px",
        alt: "Los Angeles Film School",
      },
    ];

    return (
      <GlobalLayout color="var(--gradient-light)" landingPage>
        <Helmet>
          <title>Respond to Vaping in Real Time | Rhombus E2</title>
          <meta
            name="description"
            content="Rhombus’ fully integrated vape detection systems allows for quick identification and response regardless of location while ensuring student privacy. See how.  "
          />
          <meta
            property="og:image"
            content="https://rhombus.com/img/MetaTag-E2-demo-min.png"
          />
          <script
            src={`https://fast.wistia.com/embed/medias/${videoId}.jsonp`}
            async
          ></script>
          <script
            src="https://fast.wistia.com/assets/external/E-v1.js"
            async
          ></script>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <WistiaVideoHeader
          preTitle="E2 sensor Instant demo"
          title="Watch How the Rhombus E2 Sensor Detects Vape and Allows for Rapid Response"
          blurb="The video showcases the Rhombus E2 sensor detecting vaping in student bathrooms while ensuring privacy. Operating within a 144 square foot area, it quickly syncs with the Rhombus console when vaping is detected, correlating the event with external camera footage. "
          videoId={videoId}
        />
        <InfoSlider title="The Benefits of Rhombus" data={infoSlides} />
        <IndustryFeaturedUpdated
          title="Trusted by Education Leaders"
          logos={logos}
        />
        <CTABanner />
      </GlobalLayout>
    );
  };
  const QUERY = graphql`
    query {
      infoSlide1: file(
        relativePath: {
          eq: "components/industries/updated/img/education/cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: {
          eq: "components/industries/updated/img/education/edu-alarm-monitoring.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: {
          eq: "components/industries/updated/img/education/environmental-monitoring-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: {
          eq: "components/industries/updated/img/education/privacy-screen-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      logo1: file(
        relativePath: { eq: "components/industries/img/education/logo-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: {
          eq: "components/industries/img/education/logo2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: {
          eq: "components/industries/img/education/logo3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: {
          eq: "components/industries/img/education/logo4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/industries/img/education/logo-5.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/industries/img/education/logo6-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={QUERY} render={renderContent} />;
}
